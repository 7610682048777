import { useSuspenseQuery } from "@tanstack/react-query";
import { isToday } from "date-fns";
import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { JOBS_QUERY_OPTIONS, useSuspenseJobDetail } from "shared/api/jobs";
import Button from "shared/components/Button";
import { H4 } from "shared/components/H";
import InlineLink from "shared/components/InlineLink";
import Pagination from "shared/components/Pagination";
import Table, { DataCell } from "shared/components/table";
import { formatToZonedDateTime } from "shared/util/formatText";
import { forecastOptions, getOptionFromValue, getRankFromValue } from "shared/util/forms";
import NameCell from "team/components/NameCell";
import { INTERVIEW_PATHS } from "team/constants/paths.constants";
import { TableLoader, TableOverlay } from "../../../../shared/components/TableOverlay";
import { useDefaultTableSearchParams, useTableSearchParamsCallbacks } from "../../../../shared/util/tableHelpers";

const getForecast = (val) => {
  return getOptionFromValue(val, forecastOptions)?.label || "Unknown";
};

const tableCols = [
  {
    name: "Name",
    selector: "user",
    minWidth: "200px",
    cell: (row) => <NameCell row={row.participant} healthScoreType="csm" forceNewTab={false} />,
  },
  {
    name: "Current Opportunities",
    selector: "row.participant.profile",
    minWidth: "auto",
    cell: (row) => <DataCell name="Current Opportunities" value={row.participant?.profile?.currentOpps || "0"} />,
  },
  {
    name: "Total Opportunities",
    selector: "row.participant.profile",
    minWidth: "auto",
    cell: (row) => <DataCell name="Total Opportunities" value={row.participant?.profile?.totalOpps || "0"} />,
  },
  {
    name: "Stage",
    selector: "row.participant.profile",
    minWidth: "auto",
    cell: (row) => (
      <DataCell name="Stage">
        <InlineLink href={INTERVIEW_PATHS.detail(row.id)}>
          <div>{row.latestStage}</div>
        </InlineLink>
      </DataCell>
    ),
  },
  {
    name: "Date & Time",
    selector: "row.nextInterview",
    minWidth: "auto",
    cell: (row) => {
      let displayDate = row.nextInterview;

      if (!displayDate && isToday(new Date(row.lastInterview))) {
        displayDate = row.lastInterview;
      }

      return (
        <DataCell name="Date & Time">
          {displayDate && <div>{formatToZonedDateTime(displayDate, "M/d/yy h:mm a")}</div>}
        </DataCell>
      );
    },
  },
  {
    name: "Rank",
    selector: "rank",
    maxWidth: "80px",
    minWidth: "80px",
    cell: (row) => <DataCell name="Rank" value={getRankFromValue(row.rank)} />,
  },
  {
    name: "Forecast",
    selector: "row.forecast",
    minWidth: "auto",
    maxWidth: "100px",
    cell: (row) => <DataCell name="Forecast" value={getForecast(row.forecast)} />,
  },
];

export const IN_PROCESS_TABLE_PARAMS_KEY = "inProcessTable";

export function ListProcessTableContainer({ children }) {
  return (
    <section>
      <H4 as="h2" style={{ marginTop: "30px" }}>
        Participants In Process
      </H4>
      {children}
    </section>
  );
}

/**
 * @param {object} props
 * @param {string} [props.id] - Job ID
 */
export const ListProcessTable = () => {
  const { jobId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { queryParams, parsedSearchParams } = useDefaultTableSearchParams({
    key: IN_PROCESS_TABLE_PARAMS_KEY,
    filters: { current: true },
    searchParams,
  });
  const deferredParams = React.useDeferredValue(queryParams);
  const isDeferred = JSON.stringify(queryParams) !== JSON.stringify(deferredParams);
  const { handlePageChange, handlePerRowsChange, handleSortChange } = useTableSearchParamsCallbacks({
    key: IN_PROCESS_TABLE_PARAMS_KEY,
    setSearchParams,
  });

  const { data, isFetching } = useSuspenseQuery(JOBS_QUERY_OPTIONS.processList(jobId, deferredParams));
  const isPending = isFetching || isDeferred;

  const { data: job } = useSuspenseJobDetail(jobId);

  return (
    <>
      <TableOverlay pending={isPending}>
        <Table columns={tableCols} data={data?.items} pagination={false} onSort={handleSortChange} />

        <Pagination
          rowCount={data?.total}
          currentPage={parsedSearchParams.page}
          rowsPerPage={parsedSearchParams.limit}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
        />

        <TableLoader pending={isPending} />
      </TableOverlay>

      {job && job.partner ? (
        <Button
          $small
          href={`${INTERVIEW_PATHS.create()}?jobId=${jobId}&jobName=${encodeURIComponent(
            job.title,
          )}&partnerId=${job.partner.id}&partnerName=${encodeURIComponent(job.partner.name)}`}
        >
          Add Interview
        </Button>
      ) : null}
    </>
  );
};
