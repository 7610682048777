import { useSuspenseQuery } from "@tanstack/react-query";
import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { JOBS_QUERY_OPTIONS } from "shared/api/jobs";
import { H4 } from "shared/components/H";
import InlineLink from "shared/components/InlineLink";
import Pagination from "shared/components/Pagination";
import Table, { DataCell } from "shared/components/table";
import { forecastOptions, getOptionFromValue, getRankFromValue } from "shared/util/forms";
import { getStatus } from "shared/util/interviewHelpers";
import NameCell from "team/components/NameCell";
import { INTERVIEW_PATHS } from "team/constants/paths.constants";
import { TableLoader, TableOverlay } from "../../../../shared/components/TableOverlay";
import { useDefaultTableSearchParams, useTableSearchParamsCallbacks } from "../../../../shared/util/tableHelpers";

const getForecast = (val) => {
  return getOptionFromValue(val, forecastOptions)?.label || "Unknown";
};

const tableCols = [
  {
    name: "Name",
    selector: "user",
    minWidth: "200px",
    cell: (row) => <NameCell row={row.participant} healthScoreType="csm" forceNewTab={false} />,
  },
  {
    name: "Current Opportunities",
    selector: "row.participant.profile",
    minWidth: "auto",
    cell: (row) => <DataCell name="Current Opportunities" value={row.participant?.profile?.currentOpps || "0"} />,
  },
  {
    name: "Total Opportunities",
    selector: "row.participant.profile",
    minWidth: "auto",
    cell: (row) => <DataCell name="Total Opportunities" value={row.participant?.profile?.totalOpps || "0"} />,
  },
  {
    name: "Stage",
    selector: "row.participant.profile",
    minWidth: "auto",
    cell: (row) => (
      <DataCell name="Stage">
        <InlineLink href={INTERVIEW_PATHS.detail(row.id)}>
          <div>{row.latestStage}</div>
        </InlineLink>
      </DataCell>
    ),
  },
  {
    name: "Status",
    selector: "row.rounds.0.status",
    minWidth: "auto",
    cell: (row) => <DataCell name="Status">{getStatus(row)}</DataCell>,
  },
  {
    name: "Rank",
    selector: "rank",
    maxWidth: "80px",
    minWidth: "80px",
    cell: (row) => <DataCell name="Rank" value={getRankFromValue(row.rank)} />,
  },
  {
    name: "Forecast",
    selector: "row.forecast",
    minWidth: "auto",
    maxWidth: "100px",
    cell: (row) => <DataCell name="Forecast" value={getForecast(row.forecast)} />,
  },
];

export const NOT_IN_PROCESS_TABLE_PARAMS_KEY = "notInProcessTable";

export function ListNotCurrentProcessTableContainer({ children }) {
  return (
    <section>
      <H4 as="h2" style={{ marginTop: "30px" }}>
        Participants No Longer In Process
      </H4>
      {children}
    </section>
  );
}

/**
 * @param {object} props
 * @param {string} [props.id]
 */
export const ListNotCurrentProcessTable = () => {
  const { jobId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { queryParams, parsedSearchParams } = useDefaultTableSearchParams({
    key: NOT_IN_PROCESS_TABLE_PARAMS_KEY,
    filters: { current: false },
    searchParams,
  });
  const deferredParams = React.useDeferredValue(queryParams);
  const isDeferred = JSON.stringify(queryParams) !== JSON.stringify(deferredParams);
  const { handlePageChange, handlePerRowsChange, handleSortChange } = useTableSearchParamsCallbacks({
    key: NOT_IN_PROCESS_TABLE_PARAMS_KEY,
    setSearchParams,
  });

  const { data, isFetching } = useSuspenseQuery(JOBS_QUERY_OPTIONS.processList(jobId, deferredParams));
  const isPending = isFetching || isDeferred;

  return (
    <TableOverlay pending={isPending}>
      <Table columns={tableCols} data={data?.items} pagination={false} onSort={handleSortChange} />

      <Pagination
        rowCount={data?.total}
        currentPage={parsedSearchParams.page}
        rowsPerPage={parsedSearchParams.limit}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
      />

      <TableLoader pending={isPending} />
    </TableOverlay>
  );
};
