import { QueryClient } from "@tanstack/react-query";
import * as React from "react";
import { Outlet, redirect, RouteObject } from "react-router-dom";
import { USER_OPTIONS } from "shared/api/user/user";
import { USER_ACCESS } from "shared/api/user/user.constants";
import { getRouteTreeRoot } from "shared/api/user/user.helpers";
import { LoadingContainer } from "shared/components/ds/Spinner";
import { NestedRouteErrorHandler, TopLevelRouteErrorHandler } from "shared/components/Error";
import { NotFound } from "shared/components/NotFound";
import { PrivateAppLayout } from "shared/components/PrivateAppLayout/PrivateAppLayout";
import { userAccessGuardLoader } from "shared/guards/userAccess.guard";
import { lazyRetry } from "shared/util/lazyRetry";
import { AUTOMATION_PATHS, EVENTS_PATHS, TEAM_EXPERIENCE_PREFIX } from "team/constants/paths.constants";
import { twoFactorAuthLoader } from "team/views/AccountTwoFactorAuthView";
import { interviewLoader } from "team/views/EditInterviewView/EditInterviewView";
import { jobEditStatusesLoader } from "team/views/JobEditStatusesView/JobEditStatusesView";
import { jobInterviewsLoader } from "team/views/JobInterviewsView/JobInterviewsView";
import { jobParticipantGamePlansLoader } from "team/views/JobParticipantGamePlansView/JobParticipantGamePlansView";
import { jobLoader } from "team/views/JobView";
import { pipelineLoader } from "team/views/PipelineView";

export function getTeamRoutes(queryClient: QueryClient): Array<RouteObject> {
  return [
    {
      path: TEAM_EXPERIENCE_PREFIX,
      loader: async (args) => {
        const user = await queryClient.ensureQueryData(USER_OPTIONS.user());

        if (user.type === "breakline") return null;

        const routeTreeRoot = getRouteTreeRoot(user.type);

        const { request } = args;
        const url = new URL(request.url);
        const pathname = url.pathname;

        /**
         * Because the user isn't a BreakLine teammember, we want to remove `/hq` prefix and redirect them to the
         * modified pathname without it.
         *
         * Example: `/hq/jobs` becomes `/jobs` for participants
         */
        const modifiedRoute = pathname.replace(new RegExp(`^/${TEAM_EXPERIENCE_PREFIX}/?`), routeTreeRoot);

        return redirect(modifiedRoute);
      },
      errorElement: <TopLevelRouteErrorHandler />,
      element: (
        <PrivateAppLayout>
          <React.Suspense fallback={<LoadingContainer level="page" />}>
            <Outlet />
          </React.Suspense>
        </PrivateAppLayout>
      ),
      children: [
        {
          index: true,
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { HomeView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "HomeView" */
                  "team/views/HomeView/HomeView"
                ),
              "HomeView",
            );
            return {
              Component: HomeView,
            };
          },
        },
        {
          path: "notifications",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { NotificationsView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "NotificationsView" */
                  "team/views/NotificationsView"
                ),
              "NotificationsView",
            );
            return {
              Component: NotificationsView,
            };
          },
          children: [
            {
              index: true,
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { NotificationsListView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "NotificationsListView" */
                      "shared/views/NotificationsListView/NotificationsListView"
                    ),
                  "NotificationsListView",
                );
                return {
                  Component: NotificationsListView,
                };
              },
            },
            {
              path: "tasks",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { NotificationsTasksView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "NotificationsTasksView" */
                      "team/views/NotificationTasksView/NotificationsTasksView"
                    ),
                  "NotificationsTasksView",
                );
                return {
                  Component: NotificationsTasksView,
                };
              },
            },
            {
              path: "outgoing",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { NotificationsOutgoingView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "NotificationsOutgoingView" */
                      "team/views/NotificationsOutgoingView"
                    ),
                  "NotificationsOutgoingView",
                );
                return {
                  Component: NotificationsOutgoingView,
                };
              },
            },
            {
              path: "manager",
              errorElement: <NestedRouteErrorHandler />,
              loader: () => {
                return userAccessGuardLoader([USER_ACCESS.admin], queryClient);
              },
              lazy: async () => {
                const { NotificationsManagerView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "NotificationsManagerView" */
                      "team/views/NotificationsManagerView"
                    ),
                  "NotificationsManagerView",
                );
                return {
                  Component: NotificationsManagerView,
                };
              },
            },
          ],
        },
        {
          path: "jobs",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { JobsView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "JobsView" */
                  "team/views/JobsView"
                ),
              "JobsView",
            );
            return {
              Component: JobsView,
            };
          },
        },
        {
          path: "jobs/create",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { CreateJobView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "CreateJobView" */
                  "team/views/CreateJobView/CreateJobView"
                ),
              "CreateJobView",
            );
            return {
              Component: CreateJobView,
            };
          },
        },
        {
          path: "jobs/:jobId",
          errorElement: <TopLevelRouteErrorHandler />,
          loader: async (args) => {
            return jobLoader(args, queryClient);
          },
          lazy: async () => {
            const { JobView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "JobView" */
                  "team/views/JobView"
                ),
              "JobView",
            );
            return {
              Component: JobView,
            };
          },
          children: [
            {
              index: true,
              errorElement: <NestedRouteErrorHandler />,
              loader: (args) => {
                return jobParticipantGamePlansLoader(queryClient, args);
              },
              shouldRevalidate: () => false,
              lazy: async () => {
                const { JobParticipantGamePlansView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "JobParticipantGamePlansView" */
                      "team/views/JobParticipantGamePlansView/JobParticipantGamePlansView"
                    ),
                  "JobParticipantGamePlansView",
                );
                return {
                  element: <JobParticipantGamePlansView />,
                };
              },
            },
            {
              path: "interviews",
              errorElement: <NestedRouteErrorHandler />,
              loader: (args) => {
                return jobInterviewsLoader(queryClient, args);
              },
              shouldRevalidate: () => false,
              lazy: async () => {
                const { JobInterviewsView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "JobInterviewsView" */
                      "team/views/JobInterviewsView/JobInterviewsView"
                    ),
                  "JobInterviewsView",
                );
                return {
                  element: <JobInterviewsView />,
                };
              },
            },
            {
              path: "edit",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { EditJobView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "EditJobView" */
                      "team/views/EditJobView/EditJobView"
                    ),
                  "EditJobView",
                );
                return {
                  element: (
                    <React.Suspense fallback={<LoadingContainer level="component" />}>
                      <EditJobView />
                    </React.Suspense>
                  ),
                };
              },
            },
            {
              path: "insight",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { JobInsightView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "JobInsightView" */
                      "team/views/JobInsightView/JobInsightView"
                    ),
                  "JobInsightView",
                );
                return {
                  element: (
                    <React.Suspense fallback={<LoadingContainer level="component" />}>
                      <JobInsightView />
                    </React.Suspense>
                  ),
                };
              },
            },
            {
              path: "edit/statuses",
              errorElement: <NestedRouteErrorHandler />,
              loader: (args) => {
                return jobEditStatusesLoader(queryClient, args);
              },
              shouldRevalidate: () => false,
              lazy: async () => {
                const { JobEditStatusesView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "JobEditStatusesView" */
                      "team/views/JobEditStatusesView/JobEditStatusesView"
                    ),
                  "JobEditStatusesView",
                );
                return {
                  element: <JobEditStatusesView />,
                };
              },
            },
          ],
        },
        {
          path: "account",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { AccountView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "AccountView" */
                  "team/views/AccountView"
                ),
              "AccountView",
            );
            return {
              element: <AccountView />,
            };
          },
          children: [
            {
              index: true,
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { AccountPreferencesView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "AccountPreferencesView" */
                      "shared/views/AccountPreferences/AccountPreferencesView"
                    ),
                  "AccountPreferencesView",
                );
                return {
                  element: <AccountPreferencesView />,
                };
              },
            },
            {
              path: "notifications",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { AccountNotificationsPreferencesView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "AccountNotificationsPreferencesView" */
                      "shared/views/AccountNotificationsPreferencesView"
                    ),
                  "AccountNotificationsPreferencesView",
                );
                return {
                  element: <AccountNotificationsPreferencesView />,
                };
              },
            },
          ],
        },
        {
          path: "account/two-factor-authentication/new",
          errorElement: <TopLevelRouteErrorHandler />,
          loader: () => {
            return twoFactorAuthLoader(queryClient);
          },
          lazy: async () => {
            const { AccountTwoFactorAuthView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "AccountTwoFactorAuthView" */
                  "team/views/AccountTwoFactorAuthView"
                ),
              "AccountTwoFactorAuthView",
            );
            return {
              element: <AccountTwoFactorAuthView />,
            };
          },
        },
        {
          path: "interviews/:interviewId",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { InterviewView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "InterviewView" */
                  "team/views/InterviewView"
                ),
              "InterviewView",
            );
            return {
              Component: InterviewView,
            };
          },
        },
        {
          path: "interviews/:interviewId/edit",
          errorElement: <TopLevelRouteErrorHandler />,
          loader: (args) => {
            return interviewLoader(queryClient, args);
          },
          lazy: async () => {
            const { EditInterviewView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "EditInterview" */
                  "team/views/EditInterviewView/EditInterviewView"
                ),
              "EditInterview",
            );
            return {
              Component: EditInterviewView,
            };
          },
        },
        {
          path: "interviews/create",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { CreateInterviewView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "CreateInterviewView" */
                  "team/views/CreateInterviewView"
                ),
              "CreateInterviewView",
            );
            return {
              Component: CreateInterviewView,
            };
          },
        },
        // PLAYGROUND
        {
          path: "playground",
          errorElement: <TopLevelRouteErrorHandler />,
          loader: () => {
            return userAccessGuardLoader([USER_ACCESS.admin], queryClient);
          },
          lazy: async () => {
            const { PlaygroundView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "PlaygroundView" */
                  "team/views/PlaygroundView"
                ),
              "PlaygroundView",
            );

            return {
              Component: PlaygroundView,
            };
          },
        },
        // PIPELINE
        {
          path: "pipeline-plus",
          loader: (args) => {
            return pipelineLoader(queryClient, args);
          },
          shouldRevalidate: () => false,
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { PipelineView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "PipelineView" */
                  "team/views/PipelineView"
                ),
              "PipelineView",
            );

            return {
              Component: PipelineView,
            };
          },
          children: [
            {
              index: true,
              shouldRevalidate: () => false,
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { RolesView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "RolesView" */
                      "team/views/RolesView"
                    ),
                  "RolesView",
                );

                return {
                  Component: RolesView,
                };
              },
            },
            {
              path: "interviews",
              shouldRevalidate: () => false,
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { InterviewsView } = await lazyRetry(
                  () =>
                    /* webpackChunkName: "InterviewsView" */
                    import("team/views/InterviewsView/InterviewsView"),
                  "InterviewsView",
                );

                return {
                  Component: InterviewsView,
                };
              },
            },
          ],
        },
        // EVENTS
        {
          path: "events",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { EventsView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "EventsView" */
                  "team/views/EventsView"
                ),
              "EventsView",
            );
            return {
              Component: EventsView,
            };
          },
          children: [
            {
              index: true,
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { EventTypeView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "EventTypeView" */
                      "shared/views/EventTypeView"
                    ),
                  "EventTypeView",
                );
                return {
                  Component: EventTypeView,
                };
              },
            },
            {
              path: "type/:eventTypeId",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { EventTypeView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "EventTypeView" */
                      "shared/views/EventTypeView"
                    ),
                  "EventTypeView",
                );
                return {
                  Component: EventTypeView,
                };
              },
            },
            {
              path: "type/all",
              loader: () => {
                return redirect(EVENTS_PATHS.root);
              },
            },
          ],
        },
        {
          path: "events/:eventId",
          errorElement: <TopLevelRouteErrorHandler />,
          shouldRevalidate: () => false,
          lazy: async () => {
            const { EventView, eventViewLoader } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "EventView" */
                  "team/views/EventView/EventView"
                ),
              "EventView",
            );
            return {
              loader: (args) => {
                return eventViewLoader(args, queryClient);
              },
              Component: EventView,
            };
          },
        },
        {
          path: "events/create",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { CreateEventView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "CreateEventView" */
                  "team/views/EditEventView"
                ),
              "CreateEventView",
            );
            return {
              Component: CreateEventView,
            };
          },
        },
        {
          path: "events/:eventId/edit",
          errorElement: <TopLevelRouteErrorHandler />,
          shouldRevalidate: () => false,
          lazy: async () => {
            const { EditEventView, editEventViewLoader } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "EditEventView" */
                  "team/views/EditEventView"
                ),
              "EditEventView",
            );
            return {
              loader: (args) => {
                return editEventViewLoader(args, queryClient);
              },
              Component: EditEventView,
            };
          },
        },
        {
          path: "events/:dupEventId/create",
          errorElement: <TopLevelRouteErrorHandler />,
          shouldRevalidate: () => false,
          lazy: async () => {
            const { DuplicateEventView, editEventViewLoader } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "DuplicateEventView" */
                  "team/views/EditEventView"
                ),
              "DuplicateEventView",
            );
            return {
              loader: (args) => {
                return editEventViewLoader(args, queryClient);
              },
              Component: DuplicateEventView,
            };
          },
        },
        // ASSIGNMENTS
        {
          path: "assignments",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { AssignmentsView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "AssignmentsView" */
                  "team/views/AssignmentsView/AssignmentsView"
                ),
              "AssignmentsView",
            );
            return {
              Component: AssignmentsView,
            };
          },
        },
        {
          path: "assignments/create",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { CreateAssignmentView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "CreateAssignmentView" */
                  "team/views/CreateAssignmentView"
                ),
              "CreateAssignmentView",
            );
            return {
              Component: CreateAssignmentView,
            };
          },
        },
        {
          path: "assignments/:assignmentId/edit",
          errorElement: <TopLevelRouteErrorHandler />,
          shouldRevalidate: () => false,
          lazy: async () => {
            const { EditAssignmentView, editAssignmentViewLoader } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "EditAssignmentView" */
                  "team/views/EditAssignmentView"
                ),
              "EditAssignmentView",
            );
            return {
              loader: async (args) => {
                return editAssignmentViewLoader(args, queryClient);
              },
              Component: EditAssignmentView,
            };
          },
        },
        {
          path: "assignments/:assignmentId/preview",
          errorElement: <TopLevelRouteErrorHandler />,
          shouldRevalidate: () => false,
          lazy: async () => {
            const { AssignmentPreviewView, assignmentPreviewViewLoader } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "AssignmentPreviewView" */
                  "team/views/AssignmentPreviewView/AssignmentPreviewView"
                ),
              "AssignmentPreviewView",
            );
            return {
              loader: async (args) => {
                return assignmentPreviewViewLoader(args, queryClient);
              },
              Component: AssignmentPreviewView,
            };
          },
        },
        {
          path: "assignments/:assignmentId",
          errorElement: <TopLevelRouteErrorHandler />,
          shouldRevalidate: () => false,
          lazy: async () => {
            const { AssignmentView, assignmentViewLoader } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "AssignmentView" */
                  "team/views/AssignmentView/AssignmentView"
                ),
              "AssignmentView",
            );
            return {
              loader: async (args) => {
                return assignmentViewLoader(args, queryClient);
              },
              Component: AssignmentView,
            };
          },
          children: [
            {
              index: true,
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { SubmissionsListView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "SubmissionsListView" */
                      "team/views/SubmissionsListView"
                    ),
                  "SubmissionsListView",
                );
                return {
                  element: <SubmissionsListView type="assigned" />,
                };
              },
            },
            {
              path: "submissions",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { SubmissionsListView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "SubmissionsListView" */
                      "team/views/SubmissionsListView"
                    ),
                  "SubmissionsListView",
                );
                return {
                  element: <SubmissionsListView type="submissions" />,
                };
              },
            },
          ],
        },
        {
          path: "assignments/submissions/:submissionId",
          errorElement: <TopLevelRouteErrorHandler />,
          shouldRevalidate: () => false,
          lazy: async () => {
            const { AssignmentSubmissionView, assignmentSubmissionViewLoader } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "AssignmentSubmissionView" */
                  "team/views/AssignmentSubmissionView"
                ),
              "AssignmentSubmissionView",
            );
            return {
              loader: async (args) => {
                return assignmentSubmissionViewLoader(args, queryClient);
              },
              Component: AssignmentSubmissionView,
            };
          },
        },
        // SURVEYS
        {
          path: "surveys",
          errorElement: <TopLevelRouteErrorHandler />,
          shouldRevalidate: () => false,
          lazy: async () => {
            const { SurveysView, surveysViewLoader } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "SurveysView" */
                  "team/views/SurveysView"
                ),
              "SurveysView",
            );
            return {
              loader: () => {
                return surveysViewLoader(queryClient);
              },
              Component: SurveysView,
            };
          },
        },
        {
          path: "surveys/create",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { CreateSurveyView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "CreateSurveyView" */
                  "team/views/CreateSurveyView"
                ),
              "CreateSurveyView",
            );
            return {
              Component: CreateSurveyView,
            };
          },
        },
        {
          path: "surveys/:surveyId/edit",
          errorElement: <TopLevelRouteErrorHandler />,
          shouldRevalidate: () => false,
          lazy: async () => {
            const { EditSurveyView, editSurveyViewLoader } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "EditSurveyView" */
                  "team/views/EditSurveyView/EditSurveyView"
                ),
              "EditSurveyView",
            );
            return {
              loader: async (args) => {
                return editSurveyViewLoader(args, queryClient);
              },
              Component: EditSurveyView,
            };
          },
        },
        {
          path: "surveys/:surveyId/preview",
          errorElement: <TopLevelRouteErrorHandler />,
          shouldRevalidate: () => false,
          lazy: async () => {
            const { PreviewSurveyView, previewSurveyViewLoader } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "PreviewSurveyView" */
                  "team/views/PreviewSurveyView/PreviewSurveyView"
                ),
              "PreviewSurveyView",
            );
            return {
              loader: async (args) => {
                return previewSurveyViewLoader(args, queryClient);
              },
              Component: PreviewSurveyView,
            };
          },
        },
        {
          path: "surveys/:surveyId",
          errorElement: <TopLevelRouteErrorHandler />,
          shouldRevalidate: () => false,
          lazy: async () => {
            const { SurveyDetailView, surveyDetailViewLoader } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "SurveyDetailView" */
                  "team/views/SurveyDetailView"
                ),
              "SurveyDetailView",
            );
            return {
              loader: async (args) => {
                return surveyDetailViewLoader(args, queryClient);
              },
              element: <SurveyDetailView />,
            };
          },
          children: [
            {
              index: true,
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { SurveyListView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "SurveyListView" */
                      "team/views/SurveyListView"
                    ),
                  "SurveyListView",
                );
                return {
                  element: <SurveyListView type="assigned" />,
                };
              },
            },
            {
              path: "submissions",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { SurveyListView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "SurveyListView" */
                      "team/views/SurveyListView"
                    ),
                  "SurveyListView",
                );
                return {
                  element: <SurveyListView type="submissions" />,
                };
              },
            },
          ],
        },
        {
          path: "surveys/submissions/:submissionId",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { SubmissionView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "SubmissionView" */
                  "team/views/SurveySubmissionView"
                ),
              "SubmissionView",
            );
            return {
              Component: SubmissionView,
            };
          },
        },
        {
          path: "participants",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { ParticipantsView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "ParticipantsView" */
                  "team/views/ParticipantsView/ParticipantsView"
                ),
              "ParticipantsView",
            );
            return {
              Component: ParticipantsView,
            };
          },
        },
        {
          path: "participants/:participantId/edit",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { EditParticipantView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "EditParticipantView" */
                  "team/views/EditParticipantView/EditParticipantView"
                ),
              "EditParticipantView",
            );
            return {
              Component: EditParticipantView,
            };
          },
        },
        {
          path: "participants/:participantId",
          errorElement: <TopLevelRouteErrorHandler />,
          shouldRevalidate: () => false,
          lazy: async () => {
            const { ParticipantView, participantLoader } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "ParticipantView" */
                  "team/views/ParticipantView"
                ),
              "Participant",
            );
            return {
              loader: (args) => {
                return participantLoader(queryClient, args);
              },
              Component: ParticipantView,
            };
          },
          children: [
            {
              index: true,
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { ParticipantProfileView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "ParticipantProfileView" */
                      "team/views/ParticipantProfileView/ParticipantProfileView"
                    ),
                  "ParticipantProfileView",
                );
                return {
                  Component: ParticipantProfileView,
                };
              },
            },
            {
              path: "opportunities",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { ParticipantOpportunitiesView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "ParticipantOpportunitiesView" */
                      "team/views/ParticipantOpportunitiesView/ParticipantOpportunitiesView"
                    ),
                  "ParticipantOpportunitiesView",
                );
                return {
                  Component: ParticipantOpportunitiesView,
                };
              },
            },
            {
              path: "coaching",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { ParticipantCoachingView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "ParticipantCoachingView" */
                      "team/views/ParticipantCoachingView/ParticipantCoachingView"
                    ),
                  "ParticipantCoachingView",
                );
                return {
                  Component: ParticipantCoachingView,
                };
              },
            },
            {
              path: "statuses",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { ParticipantStatusesView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "ParticipantStatusesView" */
                      "team/views/ParticipantStatusesView/ParticipantStatusesView"
                    ),
                  "ParticipantStatusesView",
                );
                return {
                  Component: ParticipantStatusesView,
                };
              },
            },
            {
              path: "saved",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { ParticipantSavedRolesView, participantSavedRolesLoader } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "ParticipantSavedRolesView" */
                      "team/views/ParticipantSavedRolesView"
                    ),
                  "ParticipantSavedRolesView",
                );

                return {
                  shouldRevalidate: () => false,
                  loader: (args) => {
                    return participantSavedRolesLoader(args, queryClient);
                  },
                  Component: ParticipantSavedRolesView,
                };
              },
            },
          ],
        },
        // TAGS
        {
          path: "tags",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { TagsView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "TagsView" */
                  "team/views/TagsView"
                ),
              "TagsView",
            );

            return {
              Component: TagsView,
            };
          },
          children: [
            {
              index: true,
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { IndexTagsSkillsView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "IndexTagsSkillsView" */
                      "team/views/TagsSkillsView"
                    ),
                  "IndexTagsSkillsView",
                );
                return {
                  Component: IndexTagsSkillsView,
                };
              },
            },
            {
              path: "skills",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { TagsSkillsView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "TagsSkillsView" */
                      "team/views/TagsSkillsView"
                    ),
                  "TagsSkillsView",
                );
                return {
                  Component: TagsSkillsView,
                };
              },
            },
            {
              path: "tracks",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { TagsTracksView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "TagsTracksView" */
                      "team/views/TagsTracksView"
                    ),
                  "TagsTracksView",
                );
                return {
                  Component: TagsTracksView,
                };
              },
            },
            {
              path: "locations",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { TagsLocationsView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "TagsLocationsView" */
                      "team/views/TagsLocationsView"
                    ),
                  "TagsLocationsView",
                );
                return {
                  Component: TagsLocationsView,
                };
              },
            },
            {
              path: "job-functions",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { TagsJobFunctionsView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "TagsJobFunctionsView" */
                      "team/views/TagsJobFunctionsView"
                    ),
                  "TagsJobFunctionsView",
                );
                return {
                  Component: TagsJobFunctionsView,
                };
              },
            },
            {
              path: "content-categories",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { TagsContentCategoriesView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "TagsContentCategoriesView" */
                      "team/views/TagsContentCategoriesView"
                    ),
                  "TagsContentCategoriesView",
                );
                return {
                  Component: TagsContentCategoriesView,
                };
              },
            },
            {
              errorElement: <NestedRouteErrorHandler />,
              path: "event-categories",
              lazy: async () => {
                const { TagsEventCategoriesView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "TagsEventCategoriesView" */
                      "team/views/TagsEventCategoriesView"
                    ),
                  "TagsEventCategoriesView",
                );
                return {
                  Component: TagsEventCategoriesView,
                };
              },
            },
            {
              path: "self-identifications",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { TagsSelfIdentificationsView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "TagsSelfIdentificationsView" */
                      "team/views/TagsSelfIdentificationsView"
                    ),
                  "TagsSelfIdentificationsView",
                );
                return {
                  Component: TagsSelfIdentificationsView,
                };
              },
            },
            {
              path: "segments",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { TagsSegmentsView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "TagsSegmentsView" */
                      "team/views/TagsSegmentsView"
                    ),
                  "TagsSegmentsView",
                );
                return {
                  Component: TagsSegmentsView,
                };
              },
            },
            {
              path: "partner-types",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { TagsPartnerTypesView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "TagsPartnerTypesView" */
                      "team/views/TagsPartnerTypesView"
                    ),
                  "TagsPartnerTypesView",
                );
                return {
                  Component: TagsPartnerTypesView,
                };
              },
            },
            {
              path: "partner-stages",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { TagsPartnerStagesView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "TagsPartnerStagesView" */
                      "team/views/TagsPartnerStagesView"
                    ),
                  "TagsPartnerStagesView",
                );
                return {
                  Component: TagsPartnerStagesView,
                };
              },
            },
            {
              path: "partner-spaces",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { TagsPartnerSpacesView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "TagsPartnerSpacesView" */
                      "team/views/TagsPartnerSpacesView"
                    ),
                  "TagsPartnerSpacesView",
                );
                return {
                  Component: TagsPartnerSpacesView,
                };
              },
            },
            {
              path: "recruiter-types",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { TagsRecruiterTypesView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "TagsRecruiterTypesView" */
                      "team/views/TagsRecruiterTypesView"
                    ),
                  "TagsRecruiterTypesView",
                );
                return {
                  Component: TagsRecruiterTypesView,
                };
              },
            },
            {
              path: "recruiter-statuses",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { TagsRecruiterStatusesView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "TagsRecruiterStatusesView" */
                      "team/views/TagsRecruiterStatusesView"
                    ),
                  "TagsRecruiterStatusesView",
                );
                return {
                  Component: TagsRecruiterStatusesView,
                };
              },
            },
          ],
        },
        // TEAM
        {
          path: "team",
          errorElement: <TopLevelRouteErrorHandler />,
          loader: () => {
            return userAccessGuardLoader([USER_ACCESS.admin], queryClient);
          },
          lazy: async () => {
            const { TeamView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "TeamView" */
                  "team/views/TeamView"
                ),
              "TeamView",
            );

            return {
              Component: TeamView,
            };
          },
        },
        {
          path: "partners",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { PartnersView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "PartnersView" */
                  "team/views/PartnersView/PartnersView"
                ),
              "PartnersView",
            );
            return {
              Component: PartnersView,
            };
          },
          children: [
            {
              index: true,
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { BreaklinePartnersView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "BreaklinePartnersView" */
                      "team/views/BreaklinePartnersView"
                    ),
                  "BreaklinePartnersView",
                );
                return {
                  element: <BreaklinePartnersView />,
                };
              },
            },
            {
              path: "all",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { BreaklinePartnersView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "BreaklinePartnersView" */
                      "team/views/BreaklinePartnersView"
                    ),
                  "BreaklinePartnersView",
                );
                return {
                  element: <BreaklinePartnersView allPartners />,
                };
              },
            },
          ],
        },
        {
          path: "partners/create",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { CreatePartnerView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "CreatePartnerView" */
                  "team/views/EditPartnerView"
                ),
              "CreatePartnerView",
            );
            return {
              Component: CreatePartnerView,
            };
          },
        },
        {
          path: "partners/:partnerId/edit",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { EditPartnerView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "EditPartnerView" */
                  "team/views/EditPartnerView"
                ),
              "EditPartnerView",
            );
            return {
              Component: EditPartnerView,
            };
          },
        },
        {
          path: "partners/:partnerId",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { PartnerView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "PartnerView" */
                  "team/views/PartnerView/PartnerView"
                ),
              "PartnerView",
            );
            return {
              Component: PartnerView,
            };
          },
          children: [
            {
              index: true,
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { PartnerRolesListView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "PartnerRolesListView" */
                      "team/views/PartnerRolesListView"
                    ),
                  "PartnerRolesListView",
                );
                return {
                  Component: PartnerRolesListView,
                };
              },
            },
            {
              path: "contact-list",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { PartnerContactsListView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "PartnerContactsListView" */
                      "team/views/PartnerContactsListView"
                    ),
                  "PartnerContactsListView",
                );
                return {
                  Component: PartnerContactsListView,
                };
              },
            },
            {
              path: "hired",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { PartnerHiredListView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "PartnerHiredListView" */
                      "team/views/PartnerHiredListView"
                    ),
                  "PartnerHiredListView",
                );
                return {
                  Component: PartnerHiredListView,
                };
              },
            },
          ],
        },
        {
          path: "partners/:partnerId/preview",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { PreviewPartnerView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "PreviewPartnerView" */
                  "team/views/PreviewPartnerView/PreviewPartnerView"
                ),
              "PreviewPartnerView",
            );
            return {
              Component: PreviewPartnerView,
            };
          },
        },
        // PARTNER CONTACTS
        {
          path: "partners/:partnerId/contact-list/:contactId",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { PartnerContactView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "PartnerContactView" */
                  "team/views/PartnerContactView/PartnerContactView"
                ),
              "PartnerContactView",
            );
            return {
              Component: PartnerContactView,
            };
          },
          children: [
            {
              index: true,
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { PartnerContactProfileView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "PartnerContactProfileView" */
                      "team/views/PartnerContactProfileView"
                    ),
                  "PartnerContactProfileView",
                );
                return {
                  Component: PartnerContactProfileView,
                };
              },
            },
          ],
        },
        {
          path: "partners/:partnerId/contact-list/create",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { CreatePartnerContactView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "CreatePartnerContactView" */
                  "team/views/CreatePartnerContactView"
                ),
              "CreatePartnerContactView",
            );
            return {
              Component: CreatePartnerContactView,
            };
          },
        },
        {
          path: "partners/:partnerId/contact-list/:contactId/edit",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { EditPartnerContact } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "EditPartnerContact" */
                  "team/views/CreatePartnerContactView"
                ),
              "EditPartnerContact",
            );
            return {
              Component: EditPartnerContact,
            };
          },
        },
        // GAME PLANS
        {
          path: "jobs/game-plan/:gamePlanId",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { JobGamePlansView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "JobGamePlansView" */
                  "team/views/JobGamePlansView/JobGamePlansView"
                ),
              "JobGamePlansView",
            );
            return {
              Component: JobGamePlansView,
            };
          },
        },
        // CONTENT LIBRARY
        {
          path: "content-library",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { ContentLibraryView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "ContentLibraryView" */
                  "team/views/ContentLibraryView"
                ),
              "ContentLibraryView",
            );
            return {
              Component: ContentLibraryView,
            };
          },
        },
        {
          path: "content-library/:contentId",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { ContentLibraryItemView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "ContentLibraryItemView" */
                  "team/views/ContentLibraryItemView"
                ),
              "ContentLibraryItemView",
            );
            return {
              Component: ContentLibraryItemView,
            };
          },
        },
        {
          path: "content-library/:contentId/edit",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { EditContentLibraryItemView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "EditContentLibraryItemView" */
                  "team/views/EditContentLibraryItemView"
                ),
              "EditContentLibraryItemView",
            );
            return {
              Component: EditContentLibraryItemView,
            };
          },
        },
        {
          path: "content-library/create",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { CreateContentLibraryItemView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "CreateContentLibraryItem" */
                  "team/views/EditContentLibraryItemView"
                ),
              "CreateContentLibraryItemView",
            );
            return {
              Component: CreateContentLibraryItemView,
            };
          },
        },
        {
          path: "hired",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { HiredView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "HiredView" */
                  "team/views/HiredView"
                ),
              "HiredView",
            );
            return {
              Component: HiredView,
            };
          },
        },
        {
          path: "shortlist",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { ShortListView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "ShortListView" */
                  "team/views/ShortListView"
                ),
              "ShortListView",
            );
            return {
              Component: ShortListView,
            };
          },
        },
        {
          path: "automation",
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { AutomationView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "AutomationView" */
                  "team/views/AutomationView"
                ),
              "AutomationView",
            );
            return {
              Component: AutomationView,
            };
          },
          children: [
            {
              index: true,
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { AutomationStageView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "AutomationStageView" */
                      "team/views/AutomationStageView"
                    ),
                  "AutomationStageView",
                );
                return {
                  Component: AutomationStageView,
                };
              },
            },
            {
              path: "stage/resource",
              loader: () => {
                return redirect(AUTOMATION_PATHS.root);
              },
            },
            {
              path: "partner/resource",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { AutomationPartnerView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "AutomationPartnerView" */
                      "team/views/AutomationPartnerView"
                    ),
                  "AutomationPartnerView",
                );
                return {
                  Component: AutomationPartnerView,
                };
              },
            },
            {
              path: "function/resource",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { AutomationFunctionView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "AutomationFunctionView" */
                      "team/views/AutomationFunctionView"
                    ),
                  "AutomationFunctionView",
                );
                return {
                  Component: AutomationFunctionView,
                };
              },
            },
            {
              path: "module/resource",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { AutomationModuleView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "AutomationModuleView" */
                      "team/views/AutomationModuleView"
                    ),
                  "AutomationModuleView",
                );
                return {
                  Component: AutomationModuleView,
                };
              },
            },
            {
              path: "stage/survey",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { AutomationStageSurveyView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "AutomationStageSurveyView" */
                      "team/views/AutomationStageSurveyView"
                    ),
                  "AutomationStageSurveyView",
                );
                return {
                  Component: AutomationStageSurveyView,
                };
              },
            },
            {
              path: "event/survey",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { AutomationEventSurveyView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "AutomationEventSurveyView" */
                      "team/views/AutomationEventSurveyView"
                    ),
                  "AutomationEventSurveyView",
                );
                return {
                  Component: AutomationEventSurveyView,
                };
              },
            },
            {
              path: "platform-event/survey",
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { AutomationPlatformEventSurveyView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "AutomationPlatformEventSurveyView" */
                      "team/views/AutomationPlatformEventSurveyView"
                    ),
                  "AutomationPlatformEventSurveyView",
                );
                return {
                  Component: AutomationPlatformEventSurveyView,
                };
              },
            },
          ],
        },
        {
          path: "*",
          element: (
            <div className="content-container flex min-h-[calc(100dvh-var(--siteheader-height))] items-center justify-center">
              <NotFound />
            </div>
          ),
        },
      ],
    },
  ];
}
